exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-test-1-mdx": () => import("./../../../src/pages/test1.mdx" /* webpackChunkName: "component---src-pages-test-1-mdx" */),
  "component---src-screens-alexa-auth-index-page-alexa-auth-uri-tsx": () => import("./../../../src/screens/alexaAuth/index.page-alexa.auth_uri.tsx" /* webpackChunkName: "component---src-screens-alexa-auth-index-page-alexa-auth-uri-tsx" */),
  "component---src-screens-google-auth-index-page-ga-auth-tsx": () => import("./../../../src/screens/googleAuth/index.page-ga.auth.tsx" /* webpackChunkName: "component---src-screens-google-auth-index-page-ga-auth-tsx" */),
  "component---src-screens-test-screen-index-page-test-page-tsx": () => import("./../../../src/screens/testScreen/index.page-test.page.tsx" /* webpackChunkName: "component---src-screens-test-screen-index-page-test-page-tsx" */),
  "component---src-templates-policies-blog-post-tsx-content-file-path-src-blog-policies-privacy-policy-mdx": () => import("./../../../src/templates/policiesBlogPost.tsx?__contentFilePath=/Users/abhimanyu/Documents/HUElite/sternet-smart-web/src/blog/policies/privacy_policy.mdx" /* webpackChunkName: "component---src-templates-policies-blog-post-tsx-content-file-path-src-blog-policies-privacy-policy-mdx" */),
  "component---src-templates-policies-blog-post-tsx-content-file-path-src-blog-policies-terms-mdx": () => import("./../../../src/templates/policiesBlogPost.tsx?__contentFilePath=/Users/abhimanyu/Documents/HUElite/sternet-smart-web/src/blog/policies/terms.mdx" /* webpackChunkName: "component---src-templates-policies-blog-post-tsx-content-file-path-src-blog-policies-terms-mdx" */),
  "component---src-templates-support-blog-template-tsx-content-file-path-src-blog-support-how-to-link-alexa-index-md": () => import("./../../../src/templates/supportBlogTemplate.tsx?__contentFilePath=/Users/abhimanyu/Documents/HUElite/sternet-smart-web/src/blog/support/howToLinkAlexa/index.md" /* webpackChunkName: "component---src-templates-support-blog-template-tsx-content-file-path-src-blog-support-how-to-link-alexa-index-md" */),
  "component---src-templates-support-blog-template-tsx-content-file-path-src-blog-support-how-to-link-google-index-md": () => import("./../../../src/templates/supportBlogTemplate.tsx?__contentFilePath=/Users/abhimanyu/Documents/HUElite/sternet-smart-web/src/blog/support/howToLinkGoogle/index.md" /* webpackChunkName: "component---src-templates-support-blog-template-tsx-content-file-path-src-blog-support-how-to-link-google-index-md" */),
  "component---src-templates-support-blog-template-tsx-content-file-path-src-blog-support-how-to-pair-index-md": () => import("./../../../src/templates/supportBlogTemplate.tsx?__contentFilePath=/Users/abhimanyu/Documents/HUElite/sternet-smart-web/src/blog/support/howToPair/index.md" /* webpackChunkName: "component---src-templates-support-blog-template-tsx-content-file-path-src-blog-support-how-to-pair-index-md" */),
  "component---src-templates-support-blog-template-tsx-content-file-path-src-blog-support-how-to-pair-troubleshoot-md": () => import("./../../../src/templates/supportBlogTemplate.tsx?__contentFilePath=/Users/abhimanyu/Documents/HUElite/sternet-smart-web/src/blog/support/howToPair/troubleshoot.md" /* webpackChunkName: "component---src-templates-support-blog-template-tsx-content-file-path-src-blog-support-how-to-pair-troubleshoot-md" */)
}

